import { ChargePoint } from './chargePoint';
import { ConnectorType } from './evse';

export enum SessionStatus {
  'unknown' = 'unknown',
  'pending' = 'pending',
  'active' = 'active',
  'finished' = 'finished',
  'failed' = 'failed',
  'expired' = 'expired'
}

export interface Session {
  id: string;
  status: SessionStatus;
  startedAt: Date | string | null;
  stoppedAt: Date | string | null;
  powerKw: number;
  amount: number;
  currency: string;
  energy: number;
}

export interface SessionResponseData {
  data: {
    chargePoint: ChargePoint;
    session: Session;
  };
}

export interface SessionSummary {
  startedAt: string;
  energy: number;
  amount: number;
  currency: string;
  name: string;
  address: string;
  connectorType: ConnectorType;
  stoppedAt: string;
  duration: string;
  status: SessionStatus;
  receiptId: number | null;
}
