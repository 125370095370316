import React from 'react';
import { useTranslation } from 'react-i18next';

import useConfig from '../../hooks/useConfig';

import styles from './styles.module.css';

const ReceiptsUrl = () => {
  const { t } = useTranslation();
  const { receiptsUrl } = useConfig();

  return (
    <section>
      <hr className={styles.line} />
      <section className={styles.receipt}>
        <span>{t('app.charge_point.receipt.label')}</span>
        <span>
          {t('app.charge_point.receipt.visit.label')}&nbsp;
          <a className={styles.link} href={receiptsUrl} target="_blank" rel="noreferrer">
            {t('app.charge_point.receipt.visit.url')}
          </a>
        </span>
      </section>
    </section>
  );
};

export default ReceiptsUrl;
