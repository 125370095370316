import { createBrowserRouter, Outlet } from 'react-router-dom';

import App from '../App';

import { ROUTE_NAMES } from './route-names';

import ChargePoint from '../screens/ChargePoint';
import ChargePointSearch from '../screens/ChargePointSearch';
import ChargingSession from '../screens/ChargingSession';
import ChargingSessionFailed from '../screens/ChargingSessionFailed';
import ChooseLanguage from '../screens/ChooseLanguage';
import CreatePinCode from '../screens/CreatePinCode';
import EnterPinCode from '../screens/EnterPinCode';
import HowToSetPinCode from '../screens/HowToSetPinCode';
import PaymentMethods from '../screens/PaymentMethods';
import ConfirmWorldLinePayment from '../screens/PaymentMethods/ConfirmWorldLinePayment';
import ReEnterCardDetails from '../screens/ReEnterCardDetails';
import SelectEvse from '../screens/SelectEvse';
import SessionSummary from '../screens/SessionSummary';
import { LocalStorage, PAYMENT_KEY } from '../storage';
import EnterLast4 from '../screens/EnterLast4';
import PricingDetails from '../screens/PricingDetails';

const updatePayment = (updates) => {
  const payment = LocalStorage.getItem(PAYMENT_KEY);

  if (payment) {
    LocalStorage.setItem(PAYMENT_KEY, {
      ...payment,
      ...updates
    });
  }
};

const setPaymentId = (paymentIntentId) => {
  updatePayment({ paymentIntentId });
};

const router = createBrowserRouter([
  {
    path: ROUTE_NAMES.root,
    element: <App />,
    children: [
      {
        path: `tenant/:tenantName/${ROUTE_NAMES.chooseLanguage}`,
        element: <ChooseLanguage />
      },
      {
        path: `tenant/:tenantName/${ROUTE_NAMES.search}`,
        element: <ChargePointSearch />
      },
      {
        path: 'tenant/:tenantName/cp/:chargePointId/evse/:evsePhysicalReference/evses',
        element: <SelectEvse />
      },
      {
        path: ROUTE_NAMES.priceDetails,
        element: <PricingDetails />
      },
      {
        path: 'tenant/:tenantName/cp/:chargePointId/evse/:evsePhysicalReference',
        element: <Outlet />,
        children: [
          {
            path: ROUTE_NAMES.choosePaymentMethod,
            element: <PaymentMethods />
          },
          {
            path: ROUTE_NAMES.confirmPayment,
            element: <ConfirmWorldLinePayment />
          },
          {
            path: `${ROUTE_NAMES.choosePaymentMethod}/:paymentRef/${ROUTE_NAMES.howToSetPinCode}`,
            loader: async ({ params }) => {
              setPaymentId(params?.paymentRef);

              return params;
            },
            element: <HowToSetPinCode />
          },
          {
            path: `${ROUTE_NAMES.choosePaymentMethod}/:paymentRef/${ROUTE_NAMES.createPinCode}`,
            loader: async ({ params }) => {
              setPaymentId(params?.paymentRef);

              return params;
            },
            element: <CreatePinCode />
          },
          {
            path: ROUTE_NAMES.enterPinCode,
            element: <EnterPinCode />
          },
          {
            path: ROUTE_NAMES.reEnterCardDetails,
            element: <ReEnterCardDetails />
          },
          {
            path: ROUTE_NAMES.resetPinCode,
            element: <CreatePinCode />
          },
          {
            path: `${ROUTE_NAMES.choosePaymentMethod}/:paymentRef/${ROUTE_NAMES.enterLast4Digits}`,
            loader: async ({ params }) => {
              setPaymentId(params?.paymentRef);

              return params;
            },
            element: <EnterLast4 />
          },
          {
            path: `${ROUTE_NAMES.chargingSession}/:sessionId`,
            loader: async ({ params }) => {
              updatePayment({
                sessionId: params?.sessionId,
                paymentIntentId: undefined
              });

              return params;
            },
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <ChargingSession />
              },
              {
                path: ROUTE_NAMES.sessionSummary,
                element: <SessionSummary />
              }
            ]
          },
          {
            path: ROUTE_NAMES.chargingSessionFailed,
            element: <ChargingSessionFailed />
          }
        ]
      },
      {
        path: `tenant/:tenantName/${ROUTE_NAMES.chargePoint}`,
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <ChargePoint />
          }
        ]
      },
      {
        path: `tenant/:tenantName/cp/:chargePointId/${ROUTE_NAMES.selectEvse}`,
        element: <SelectEvse />
      }
    ]
  }
]);

export default router;
