import styles from "./styles.module.css";

interface CardProps {
  children: any;
  className?: string;
}

const Card = ({ children, className }: CardProps) => {
  return (
    <section className={`blue-card ${styles.card} ${className}`}>
      {children}
    </section>
  );
};

export default Card;
