import { Config } from 'src/models/config';
import { Payment } from 'src/models/payment';

export const CONFIG_KEY = 'config';
export const PAYMENT_KEY = 'payment';
export const CHARGE_POINT_KEY = 'charge_point';
export const LANGUAGE_KEY = 'language';

export class LocalStorage {
  static getItem(key: string): Config | Payment | string | undefined {
    const item = localStorage.getItem(key);

    return item ? JSON.parse(item) : item;
  }

  static setItem(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  static removeItem(key: string) {
    localStorage.removeItem(key);
  }
}
