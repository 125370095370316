import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components
import Header from '../../components/Header';
import Logo from '../../components/Logo';
import Input from '../../components/Input';
import Button, { ButtonType } from '../../components/Button';

// Constants and models
import { ROUTE_NAMES } from '../../router/route-names';
import { ENDPOINT_URL } from '../../constants/endpoints';
import { Http } from '../../http';
import { DisplayMode } from '../../constants/display-mode';

import useConfig from '../../hooks/useConfig';
import { handleAndMonitorError } from '../../helpers';

import styles from './styles.module.css';
import { formatEvsePhysicalReference } from '../../helpers/charge-point-validate';
import { LANGUAGE_KEY, LocalStorage } from '../../storage';
import { buildTenantUrl } from '../../helpers/routing';

const ChargePointSearch = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { logoUrl } = useConfig();
  const { displayMode, searchModeTextHint } = useConfig();
  const [evsePhysicalReference, setEvsePhysicalReference] = useState('');
  const handlePhysicalReferenceChange = (value: string) => {
    // Trim the value before setting it in the state
    setEvsePhysicalReference(value.trim());
  };
  const [error, setError] = useState<string | null>();
  const { name, languages } = useConfig();

  //  check if a specific language is set in localStorage
  const selectedLanguage = LocalStorage.getItem(LANGUAGE_KEY);

  const path = window.location.pathname.split('/');
  const tenantIndex = path.findIndex(element => element === 'tenant');
  const tenantName = path[tenantIndex + 1];

  useEffect(() => {
    if (!selectedLanguage && DisplayMode.Search) {
      navigate(`../tenant/${tenantName}/${ROUTE_NAMES.chooseLanguage}`);
    }
  }, [selectedLanguage]);
  const onNext = (response: any) => {
    if (displayMode === DisplayMode.ChargePoint) {
      navigate(
        buildTenantUrl({
          pathPrefix: '..',
          tenant: response.headers['tennant'],
          routeName: ROUTE_NAMES.chargePoint
        })
      );
    } else {
      navigate(
        buildTenantUrl({
          pathPrefix: '..',
          tenant: response.headers['tenant'],
          chargePointId: response?.data?.data?.chargePointId,
          evseId: evsePhysicalReference,
          routeName: ROUTE_NAMES.selectEvse
        })
      );
    }
  };

  const handleSearch = async () => {
    if (!evsePhysicalReference) {
      setError(t('app.search_charge_point.input.empty.value.error'));
      return;
    }

    try {
      const response = await new Http().get(ENDPOINT_URL.evse(evsePhysicalReference), name);
      onNext(response);
    } catch (e) {
      handleAndMonitorError(
        `Error while trying to retrieve charge point details from ${ENDPOINT_URL.evse(evsePhysicalReference ?? '')}: ${e}`
      );
      try {
        const response = await new Http().get(
          ENDPOINT_URL.evse(formatEvsePhysicalReference(evsePhysicalReference)),
          name
        );
        onNext(response);
      } catch (nextError) {
        setError(t('app.search_charge_point.not.found.error'));
        handleAndMonitorError(
          `Error while trying to retrieve charge point details from ${ENDPOINT_URL.evse(evsePhysicalReference ?? '')}: ${nextError}`
        );
      }
    }
  };

  return (
    <section className="page-container">
      <Header
        showBackButton={!!(languages && languages?.length)}
        label={t('app.search_charge_point.header')}
        onClick={() =>
          navigate(
            buildTenantUrl({
              pathPrefix: '..',
              tenant: tenantName,
              routeName: ROUTE_NAMES.chooseLanguage
            })
          )
        }
      />
      {logoUrl && <Logo logoUrl={logoUrl} />}
      <section className={styles.container}>
        <Input
          placeholder={searchModeTextHint}
          label={t('app.search_charge_point.input.label')}
          type="text"
          error={error}
          setValue={handlePhysicalReferenceChange}
        />
        <Button label={t('app.search_charge_point.button')} type={ButtonType.primary} onClick={handleSearch} />
      </section>
    </section>
  );
};

export default ChargePointSearch;
