import React from 'react';

import StatusIndicator from '../StatusIndicator';
import ChargePointCard from './ChargePointCard';

interface ChargePointCointainerProps {
  name: string | undefined;
  location: string | undefined;
  numberOfEvses: number;
  status: string;
}

const ChargePointContainer = ({ name, location, numberOfEvses, status }: ChargePointCointainerProps) => {
  return (
    <>
      <ChargePointCard name={name} location={location} />
      <hr className="separator" />
      <StatusIndicator status={status} numberOfEvses={numberOfEvses} />
    </>
  );
};

export default ChargePointContainer;
