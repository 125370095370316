import React, { useEffect, useState } from 'react';

//Hooks
import useConfig from '../../../hooks/useConfig';
import usePayment from '../../../hooks/usePayment';
import useSession from '../../../hooks/useSession';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components
import Button, { ButtonType } from '../../Button';
import TariffSelector from './TariffSelector';
import Image from '../../Image';

// Models and constants
import { FA_ICONS } from '../../../constants';
import { DetailsSectionProps } from '../../../models/evse';
import { Payment } from '../../../models/payment';
import { ROUTE_NAMES } from '../../../router/route-names';
import { LANGUAGE_KEY, LocalStorage, PAYMENT_KEY } from '../../../storage';
import { isEvseAvailable, hasEvseActiveSessionRunnedHere } from '../../../helpers/evse-statuses';

import styles from './styles.module.css';
import { buildTenantUrl } from '../../../helpers/routing';

const DetailsSection = ({
  status,
  currency,
  deposit,
  tenant,
  chargePointId,
  evseId,
  evsePhysicalReference,
  connectorId,
  description,
  additionalInfo,
  ...props
}: DetailsSectionProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { termsAndConditionsUrl, termsAndConditionsChecked } = useConfig();
  const [checked, setChecked] = useState(termsAndConditionsChecked);
  const [error, setError] = useState<string | null>();
  const { paymentProvider } = useConfig();
  const { createPayment } = usePayment();
  const { checkSessionAvailability } = useSession();
  const [hasAvailableSession, setHasAvailableSession] = useState(false);
  const [sessionId, setSessionId] = useState(undefined);
  const languageTag = LocalStorage.getItem(LANGUAGE_KEY) as string | undefined;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const [language, region] = languageTag.split('_');
  const hideInfo = (additionalInfo && !additionalInfo[language as string]) || !additionalInfo;

  const updatePaymentDetails = () => {
    const payment = LocalStorage.getItem(PAYMENT_KEY) as Payment;

    const updatedPayment: Payment = {
      ...payment,
      amount: deposit,
      currency,
      chargePointId,
      evseId,
      evsePhysicalReference,
      connectorId,
      sessionId: sessionId
    };

    LocalStorage.setItem(PAYMENT_KEY, updatedPayment);
  };

  useEffect(() => {
    const fetchAvailableSessions = async () => {
      try {
        //Check for available sessions
        const availableSession = await checkSessionAvailability(chargePointId, evseId);

        if (availableSession && availableSession.data.hasActiveSession) {
          setHasAvailableSession(true);
          setSessionId(availableSession.data.sessionId);
        }
      } catch (e) {
        setHasAvailableSession(false);
        //Set error only when the status is either charging or preparing
        //and there is no available session
        // (status === HardwareStatus.charging || status === HardwareStatus.preparing) &&
        //   setError(t('app.select_evse.details.pay.now.forbidden'));
      }
    };

    fetchAvailableSessions().then();
  }, [status]);

  const navigateToPriceDetails = () => {
    if (hideInfo) return;

    navigate(`../${ROUTE_NAMES.priceDetails}`, { state: { description: additionalInfo } });
  };

  const navigateToPayment = async () => {
    updatePaymentDetails();

    if (!checked) {
      setError(t('app.select_evse.terms_and_conditions.unchecked.error'));
      return;
    }

    if (paymentProvider === 'worldline') {
      const slicePoint = window.location.href.indexOf('/evses');
      const newUrl = window.location.href.slice(0, slicePoint);
      const returnUrl = `${newUrl}/confirm-payment`;

      const createPaymentIntentResponse = await createPayment(
        chargePointId,
        evseId,
        deposit,
        currency,
        languageTag,
        returnUrl
      );
      const payment = LocalStorage.getItem(PAYMENT_KEY) as Payment | undefined;

      if (createPaymentIntentResponse.status !== 200) setError(t('app.payment_method.confirm.payment.error'));

      if (payment) {
        const updatedPayment = {
          ...payment,
          paymentIntentId: createPaymentIntentResponse.data?.responseBody?.hostedCheckoutId
        };

        LocalStorage.setItem(PAYMENT_KEY, updatedPayment);

        window.location.replace(createPaymentIntentResponse.data?.responseBody?.redirectUrl);
      }
    } else if (paymentProvider === 'stripe') {

      navigate(
        buildTenantUrl({
          pathPrefix: '..',
          tenant: tenant,
          chargePointId: chargePointId,
          evseId: evsePhysicalReference,
          routeName: ROUTE_NAMES.choosePaymentMethod
        }),
        { replace: true }
      );
    }
  };

  const navigateToEnterPin = () => {
    updatePaymentDetails();

    if (!checked) {
      setError(t('app.select_evse.terms_and_conditions.unchecked.error'));
      return;
    }

    navigate(
      buildTenantUrl({
        pathPrefix: '..',
        tenant: tenant,
        chargePointId: chargePointId,
        evseId: evsePhysicalReference,
        routeName: ROUTE_NAMES.enterPinCode
      })
    );
  };

  return (
    <section className={styles.container}>
      <section className={styles.header}>
        <section className={styles.details}>
          <span className="bold">{t('app.select_evse.details.header')}</span>
          {!hideInfo && (
            <span onClick={navigateToPriceDetails}>
              <Image src={FA_ICONS.faCircleInfo} />
            </span>
          )}
        </section>
        {description ? (
          <p dangerouslySetInnerHTML={{ __html: (description[language ?? 'en'] as any) ?? undefined }} />
        ) : null}
      </section>
      <section className={styles.tariff}>
        <TariffSelector deposit={deposit} currency={currency} {...props} />
      </section>
      <section className={styles.tariffAction}>
        <Button
          disabled={!(isEvseAvailable(status) || hasEvseActiveSessionRunnedHere(status, hasAvailableSession))}
          label={
            hasEvseActiveSessionRunnedHere(status, hasAvailableSession)
              ? t('app.select_evse.details.view.session.button')
              : t('app.select_evse.details.pay.now.button')
          }
          type={ButtonType.primary}
          onClick={isEvseAvailable(status) ? navigateToPayment : navigateToEnterPin}
          icon={FA_ICONS.faAngleRight}
        />
      </section>

      {termsAndConditionsUrl && (
        <section className={styles.terms}>
          <input
            type="checkbox"
            checked={checked}
            className={styles.termsInput}
            onChange={() => {
              setChecked(currentState => !currentState);
            }}
          />
          <section className={styles.termsDescription}>
            <span
              onClick={() => {
                setChecked(currentState => !currentState);
              }}
            >
              {t('app.select_evse.details.terms.and.conditions.label')}{' '}
            </span>

            <a href={termsAndConditionsUrl} target="_blank" className={styles.termsUrl}>
              {t('app.select_evse.details.terms.and.conditions.url')}
            </a>
          </section>
        </section>
      )}

      {error && <section className={'general-error'}>{error}</section>}
    </section>
  );
};

export default DetailsSection;
