import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';

import './index.css';
import './i18n';
import router from './router';
import { handleAndMonitorError } from './helpers';

const apiKey = (process.env.DF_REACT_APP_BUGSNAG_API_KEY || process.env.REACT_APP_BUGSNAG_API_KEY) ?? '';
Bugsnag.start({
  apiKey,
  plugins: [new BugsnagPluginReact()]
});
BugsnagPerformance.start({ apiKey });
const ErrorBoundary = Bugsnag?.getPlugin('react')?.createErrorBoundary(React);

if (!ErrorBoundary) {
  handleAndMonitorError('Error while trying to initialize Bugsnag');
  throw 'Error while trying to initialize Bugsnag';
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <ErrorBoundary>
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </ErrorBoundary>
);
