import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

// Components
import Header from '../../components/Header';
import Language from '../../components/Language';
import Notification from '../../components/Notification';

import { DisplayMode } from '../../constants/display-mode';
import useConfig from '../../hooks/useConfig';
import { addTranslation } from '../../i18n';
import { ROUTE_NAMES } from '../../router/route-names';

import Loader from '../../components/Loader';
import Logo from '../../components/Logo';
import { ENDPOINT_URL } from '../../constants/endpoints';
import { handleAndMonitorError } from '../../helpers';
import { formatEvsePhysicalReference } from '../../helpers/charge-point-validate';
import { Http } from '../../http';
import { LANGUAGE_KEY, LocalStorage } from '../../storage';
import styles from './styles.module.css';
import { buildTenantUrl } from '../../helpers/routing';

const ChooseLanguage = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { languages, logoUrl, displayMode } = useConfig();
  const [languageCodesList, setLanguageCodesList] = useState<string[]>([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const language = LocalStorage.getItem(LANGUAGE_KEY);

  const path = window.location.pathname.split('/');
  const tenantIndex = path.findIndex(element => element === 'tenant');
  const tenantName = path[tenantIndex + 1];

  const fetchChargePointData = async () => {
    const startSessionIndex = window.location.pathname
      .split('/')
      .findIndex(element => element === ROUTE_NAMES.sessionStart);
    const evsePhysicalReference = path[startSessionIndex + 1];
    try {
      const response = await new Http().get(ENDPOINT_URL.chargePoint(evsePhysicalReference), tenantName);
      setLoading(false);
      return response.data.data;
    } catch (e) {
      try {
        const response = await new Http().get(
          ENDPOINT_URL.chargePoint(formatEvsePhysicalReference(evsePhysicalReference)),
          tenantName
        );
        setLoading(false);
        return response.data.data;
      } catch (nextError) {
        setError(true);
        setLoading(false);
        return undefined;
      }
    }
  };

  useEffect(() => {
    if (!languages || !languages.length) {
      navigateBasedOnDisplayMode();
      return;
    }

    const availableLanguagesCodes = languages.map(code => {
      return code;
    });
    setLanguageCodesList(availableLanguagesCodes);
    setLoading(false);
  }, [languages]);

  const setLanguage = useCallback(
    async (languageCode: string) => {
      try {
        await addTranslation(languageCode);
        await i18n.changeLanguage(languageCode);

        LocalStorage.setItem(LANGUAGE_KEY, languageCode);

        if (location.state?.goBackToSelectEvseURL) {
          navigate(location.state.goBackToSelectEvseURL);
        } else {
          await navigateBasedOnDisplayMode();
        }
      } catch (e) {
        handleAndMonitorError(`Error while trying to change language for ${languageCode} language code: ${e}`);
      }
    },
    [language]
  );

  const navigateBasedOnDisplayMode = useCallback(async () => {
    switch (displayMode) {
      case DisplayMode.ChargePoint:
        navigate(
          buildTenantUrl({
            pathPrefix: '..',
            tenant: tenantName,
            routeName: ROUTE_NAMES.chargePoint
          })
        );
        break;
      case DisplayMode.Evse:
        const chargePointRes = await fetchChargePointData();
        if (!chargePointRes) {
          setLoading(true);
          return;
        }

        navigate(
          buildTenantUrl({
            pathPrefix: '..',
            tenant: tenantName,
            chargePointId: chargePointRes?.chargePoint.id,
            evseId: chargePointRes?.evses[0].physicalReference,
            routeName: ROUTE_NAMES.selectEvse
          })
        );
        break;
      case DisplayMode.Search:
        //Find session-start
        const startSessionIndex = window.location.pathname
          .split('/')
          .findIndex(element => element === ROUTE_NAMES.sessionStart);

        if (startSessionIndex < 0) {
          navigate(
            buildTenantUrl({
              pathPrefix: '..',
              tenant: tenantName,
              routeName: ROUTE_NAMES.search
            })
          );
        } else {
          //Fetch chargePoint data if session-start is present
          const chargePoint = await fetchChargePointData();
          if (!chargePoint) {
            setLoading(true);
            return;
          }

          navigate(
            buildTenantUrl({
              pathPrefix: '..',
              tenant: tenantName,
              chargePointId: chargePoint?.chargePoint.id,
              evseId: chargePoint?.evses[0].physicalReference,
              routeName: ROUTE_NAMES.selectEvse
            })
          );
        }

        break;
      default:
        handleAndMonitorError(
          `Error while trying to load application. The following display mode ${displayMode}, which is passed from configuration is not valid.`
        );
        setError(true);
        return;
    }
  }, [displayMode]);

  if (loading) {
    return <Loader />;
  }

  return (
    <section className="page-container">
      <section>
        <Header showBackButton={false} label={t('app.choose_language.header')} />
        {logoUrl && <Logo logoUrl={logoUrl} />}
        <ul className={styles.flexItems}>
          {languageCodesList.map(code => (
            <Language key={code} code={code} onClick={setLanguage} />
          ))}
        </ul>
      </section>
      {error && <section className={'general-error'}>{t('app.charge_point.invalid.display.mode.error')}</section>}

      <Notification
        type="info"
        header={t('app.choose_language.notification.text')}
        show={true}
        useTimeout={true}
        position="bottom"
        showClose={true}
      />
    </section>
  );
};

export default ChooseLanguage;
