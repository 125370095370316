const buildAppRootPath = () => {
  // Get the pathname from the window location, excluding the leading slash
  let currentPath = window.location.pathname.split('/tenant')[0];

  // Ensure that the current path ends with a trailing slash
  if (!currentPath?.endsWith('/')) {
    currentPath += '/';
  }

  // Initialize the root path as empty string without any dynamic url parameters
  let appRootPath = '';

  // function which builds dynamic url params
  const dynamicUrlParam = (index: number) => `/:param${index}`;

  // update the root path with the number of dynamic url params coming from the server
  for (let i = 0; i < currentPath?.split('/')?.length - 2; i++) {
    appRootPath += dynamicUrlParam(i);
  }

  return appRootPath;
};

export default buildAppRootPath;
