import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import Header from '../../components/Header';
import Button, { ButtonType } from '../../components/Button';
import ChargePointCard from '../../components/ChargePointContainer/ChargePointCard';
import Image from '../../components/Image';
import PreparingModal from '../../components/PreparingModal';
import Loader from '../../components/Loader';

// Constants and models
import { ROUTE_NAMES } from '../../router/route-names';
import { FA_ICONS, GIF } from '../../constants';
import { ENDPOINT_URL } from '../../constants/endpoints';
import { CHARGE_POINT_KEY, LocalStorage, PAYMENT_KEY } from '../../storage';
import { Payment } from '../../models/payment';
import { Session, SessionStatus } from '../../models/session';
import { CURRENCY_SYMBOL } from '../../constants/currencySymbol';
import { ChargePoint } from '../../models/chargePoint';
import { convertWattToKilowatt, handleAndMonitorError } from '../../helpers';
import styles from './styles.module.css';
import useSession from '../../hooks/useSession';
import useNavigationHandler from '../../hooks/useNavigationHandler';

// constant for getting first time session data after 5 seconds (requirement)
const TIMEOUT_PERIOD = 5000;
// constant for getting fresh session data on every 10 seconds (10000 milliseconds)
const INTERVAL_PERIOD = 10000;

const ChargingSession = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState<string | undefined>();
  const [preparing, setPreparing] = useState(false);
  const [session, setSession] = useState<Session | undefined>();
  const payment = LocalStorage.getItem(PAYMENT_KEY) as Payment;
  const chargePoint = LocalStorage.getItem(CHARGE_POINT_KEY) as ChargePoint | undefined;
  const { stopSession, getSession } = useSession();
  const { handleGoHomeButton } = useNavigationHandler();
  const [disableStopButton, setDisableStopButton] = useState(false);

  useEffect(() => {
    if (!location?.state?.pin) {
      navigate(`../${ROUTE_NAMES.enterPinCode}`);
      return;
    }

    const retrieveSession = async () => {
      if (!location.state.sessionId) return;

      try {
        const sessionResponse = await getSession(
          payment.chargePointId,
          payment.evseId,
          location.state.sessionId,
          location.state.pin
        );

        const sessionData: Session = sessionResponse?.data?.data?.session;

        switch (sessionData.status) {
          case SessionStatus.pending:
            setPreparing(true);
            break;
          case SessionStatus.active:
            setSession(sessionData);
            setPreparing(false);
            break;
          case SessionStatus.expired:
            navigate(`../${ROUTE_NAMES.chargingSessionFailed}`, {
              state: {
                status: SessionStatus.failed,
                title: 'app.errors.title.warning',
                description: 'app.errors.descr.expired_session'
              },
              replace: true
            });
            break;
          case SessionStatus.finished:
            if (disableStopButton === true) {
              return; // The button navigation is doing the job. There is a rare case where both navigation are triggered which produces 404 error(i.e. "../summary/summary" page which doesn't exist).
            }

            navigate(ROUTE_NAMES.sessionSummary, {
              state: {
                sessionId: sessionData.id,
                pin: location.state.pin
              },
              replace: true
            });
            break;
          default:
            navigate(`../${ROUTE_NAMES.chargingSessionFailed}`, {
              state: {
                status: sessionData.status
              },
              replace: true
            });
        }
      } catch (e) {
        if (e?.response?.data?.error === 'app.enter_pin_code.incorrect.pin') {
          navigate(`../${ROUTE_NAMES.enterPinCode}`, {
            state: {
              error: e?.response?.data?.error
            }
          });
        } else {
          let jsonError;

          try {
            jsonError = JSON.parse(e?.response.data.error);
          } catch (je) {}

          if (jsonError && jsonError.localeTitleKey && jsonError.localeDescrKey) {
            navigate(`../${ROUTE_NAMES.chargingSessionFailed}`, {
              state: {
                status: SessionStatus.failed,
                title: jsonError.localeTitleKey,
                description: jsonError.localeDescrKey
              },
              replace: true
            });
          } else {
            handleGoHomeButton();
          }
        }

        handleAndMonitorError(
          `Error while trying to access Charging Session screen from ${ENDPOINT_URL.session(
            payment.chargePointId,
            payment.evseId,
            payment?.sessionId ?? -1,
            location.state.pin
          )}: ${e?.response?.data?.error ?? e}`
        );
      }
    };

    const timeout = setTimeout(
      () => {
        retrieveSession().then();
      },
      location?.state?.enterPin ? 0 : TIMEOUT_PERIOD
    );
    const interval = setInterval(retrieveSession, INTERVAL_PERIOD);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, []);

  if (!chargePoint && !preparing) {
    return <Loader />;
  }

  if (!payment) {
    throw 'Missing payment details';
  }

  const handleStopSession = async () => {
    if (!location?.state?.pin) {
      throw 'Missing pin code';
    }

    try {
      setDisableStopButton(true);

      const stopSessionResponse = await stopSession(payment.chargePointId, payment.evseId, location.state.pin);

      if (stopSessionResponse.status !== 200) {
        setDisableStopButton(false);

        console.log('Error while trying to stop a session', stopSessionResponse);

        setError(t('app.charging_session.end.session.error'));

        return;
      }

      navigate(ROUTE_NAMES.sessionSummary, {
        state: {
          sessionId: stopSessionResponse?.data?.sessionId,
          pin: location.state.pin
        },
        replace: true
      });
    } catch (e) {
      handleAndMonitorError(
        `Error while trying to stop a session from ${ENDPOINT_URL.stopSession(
          payment.chargePointId,
          payment.evseId
        )}: ${e}`
      );
      setError(t('app.charging_session.end.session.error'));
    } finally {
      setDisableStopButton(false);
    }
  };

  const amountData = (currency: string, price: number): string => {
    return `${CURRENCY_SYMBOL[currency]} ${price.toFixed(2)}`;
  };

  const energyData = (energy: number): string => {
    return `${convertWattToKilowatt(energy)} kWh`;
  };

  return (
    <section className="page-container">
      <Header showBackButton label={t('app.charging_session.header')} onClick={handleGoHomeButton} />
      <ChargePointCard name={chargePoint?.name} location={chargePoint?.address} className={styles.chargingPoint} />
      {session && (
        <>
          <section className={styles.mainContent}>
            <section className={`blue-card ${styles.charging}`}>
              <Image src={FA_ICONS.faCharge} />
              <span>{t('app.charging_session.status.label')}</span>
            </section>
            <hr className="separator" />
            {!preparing && (
              <section className={`blue-card ${styles.battery}`}>
                <Image src={GIF.battery} />
              </section>
            )}
            <hr className="separator" />
            <section className={`blue-card ${styles.details}`}>
              <section className={styles.detailsRow}>
                <span>{t('app.charging_session.details.deposit.left.label')}</span>
                <span className="bold">{amountData(session.currency, payment.amount - session.amount)}</span>
              </section>
              <section className={styles.detailsRow}>
                <span>{t('app.charging_session.details.energy.label')}</span>
                <span className="bold">{energyData(session.energy)}</span>
              </section>
              <section className={styles.detailsRow}>
                <span>{t('app.charging_session.details.amount.label')}</span>
                <span className="bold">{amountData(session.currency, session.amount)}</span>
              </section>
            </section>
          </section>
          <Button
            label={t('app.charging_session.end.session.button')}
            type={ButtonType.primary}
            onClick={handleStopSession}
            disabled={disableStopButton}
          />
        </>
      )}
      {error && <span className={styles.error}>{error}</span>}
      {preparing && <PreparingModal />}
    </section>
  );
};

export default ChargingSession;
