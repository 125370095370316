export const ENDPOINT_URL = {
  config: '/api/v1/configs',
  evse: (evsePhysicalReference: string) => `/api/v1/evses?evsePhysicalReference=${evsePhysicalReference}`,
  chargePoint: (evsePhysicalReference: string) =>
    `/api/v1/charge-points?evsePhysicalReference=${evsePhysicalReference}`,
  session: (chargePointId: number, evseId: number, sessionId: number, pin: string) => {
    return `/api/v1/charge-points/${chargePointId}/session/${sessionId}/evse/${evseId}?pin=${pin}`;
  },
  startSession: (chargePointId: number, evseId: number) =>
    `/api/v1/charge-points/${chargePointId}/session/${evseId}/start`,
  stopSession: (chargePointId: number, evseId: number) =>
    `/api/v1/charge-points/${chargePointId}/session/${evseId}/stop`,
  translations: (languageCode: string) => `/api/v1/translations/${languageCode}`,
  stripePayments: '/api/v1/payments/stripe',
  createStripeFailedTransaction: '/api/v1/payments/stripe/failed',
  //MerchantId is required
  worldLinePayments: '/api/v1/payments/worldline',
  getPaymentStatus: (paymentIntentId: string) => `/api/v1/payments/${paymentIntentId}/status`,
  forgotPin: (chargePointId: number, evseId: number, last4: string, expYear: string, expMonth: string) =>
    `/api/v1/charge-points/${chargePointId}/session/${evseId}/pin?last4=${last4}&expYear=${expYear}&expMonth=${expMonth}`,
  updateNewPin: (chargePointId: number, evseId: number) =>
    `/api/v1/charge-points/${chargePointId}/session/${evseId}/pin`,
  sessionFinished: (chargePointId: number, evseId: number, sessionId: string) =>
    `/api/v1/charge-points/${chargePointId}/session/${evseId}/finished?sessionId=${sessionId}`,
  checkSessionAvailability: (chargePointId: number, evseId: number) =>
    `/api/v1/charge-points/${chargePointId}/session/${evseId}/availability`,
  receipt: (receiptId: number) => `/api/v1/receipts/${receiptId}`
};
