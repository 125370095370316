import React, { useEffect, useState } from 'react';

import { FA_ICONS } from '../../constants';
import Image from '../Image';
import styles from './styles.module.css';

const NOTIFICATION_TIMEOUT = 3000;
const ICON_CLASS = {
  success: FA_ICONS.faCircleCheck,
  error: FA_ICONS.faCircleXmark,
  info: FA_ICONS.faCircleInfo,
  warning: FA_ICONS.faTrinagleExclamation
};

type IconType = 'success' | 'error' | 'info' | 'warning';
type Position = 'bottom' | 'unset';
interface NotificationProps {
  type: IconType;
  show: boolean;
  header: string;
  position: Position;
  body?: string;
  useTimeout?: boolean;
  showClose?: boolean;
}

const Notification = ({ type, show, header, position, body, useTimeout, showClose }: NotificationProps) => {
  const [display, setDisplay] = useState(show);

  useEffect(() => {
    let timeout;

    if (useTimeout) {
      timeout = setTimeout(() => {
        setDisplay(false);
      }, NOTIFICATION_TIMEOUT);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  if (!display) {
    return <></>;
  }

  return (
    <section className={`${styles.container} ${styles[type]} ${styles[position]}`}>
      <section className={styles.header}>
        <section className={styles.headerMain}>
          <Image src={ICON_CLASS[type]} />
          <span className={`${body ? 'bold' : ''} ${styles.headerText}`}>{header}</span>
        </section>
        {showClose && (
          <section onClick={() => setDisplay(false)} className={styles.close}>
            <Image src={FA_ICONS.faXmark} />
          </section>
        )}
      </section>
      {body && <span className={styles.body}>{body}</span>}
    </section>
  );
};

export default Notification;
