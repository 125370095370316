import React from 'react';

import Image from '../Image';
import styles from './styles.module.css';

export enum ButtonType {
  primary = 'primary',
  secondary = 'secondary'
}

interface ButtonProps {
  label: string;
  type: ButtonType;
  icon?: string;
  className?: string;
  classNameIcon?: string;
  disabled?: boolean;
  onClick?: (event?: any) => void;
}

const Button = ({ label, type, icon, className, classNameIcon, disabled, onClick }: ButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={`${styles.button} ${type === ButtonType.primary ? styles.primary : styles.secondary} ${className} ${disabled && styles.disabled}`}
      disabled={disabled ?? false}
    >
      <span className={styles.label}>{label}</span>
      {icon && <Image src={icon} className={classNameIcon ?? ''} />}
    </button>
  );
};

export default Button;
