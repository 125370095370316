import React from 'react';

import Image from '../Image';
import { FA_ICONS } from '../../constants';

import styles from './styles.module.css';

interface InputProps {
  label?: string;
  placeholder?: string;
  type: string;
  error?: string | null;
  setValue: (value: any) => void;
  showSecond?: boolean;
  setSecondValue?: (value: any) => void;
}

const Input = ({ label, placeholder, type, error, setValue, setSecondValue, showSecond }: InputProps) => {
  return (
    <section className={styles.container}>
      <label className={styles.label}>{label}</label>
      <section className={styles.inputs}>
        <section className={`${styles.inputContainer} ${error ? styles.errorContainer : ''}`}>
          <input
            placeholder={placeholder}
            type={type}
            className={styles.input}
            onChange={event => setValue(event?.target.value ?? '')}
          />
          {type === 'password' && <Image src={FA_ICONS.faMaskedInput} />}
        </section>
        {showSecond && setSecondValue && (
          <>
            <span className={styles.separator}>/</span>
            <section className={`${styles.inputContainer} ${error ? styles.errorContainer : ''}`}>
              <input
                type={type}
                className={styles.input}
                onChange={event => setSecondValue(event?.target.value ?? '')}
              />
            </section>
          </>
        )}
      </section>
      {error && <span className={'general-error'}>{error}</span>}
    </section>
  );
};

export default Input;
