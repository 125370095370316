import {ENDPOINT_URL} from '../../constants/endpoints';
import {Http} from '../../http';
import useConfig from '../useConfig';
import {PaymentProvider} from "../../constants/payment-provider";

export default function usePayment() {
  const { name, paymentProvider, worldLineGroupCards } = useConfig();

  const createPayment = async (chargePointId: number, evseId: number, amount: number, currency: string, locale?: string, returnUrl?: string) => {
    let params: any = {
      chargePointId,
      evseId,
      amount: amount * 100,
      currency: currency
    };

    let url;

    if (paymentProvider == PaymentProvider.WORLDLINE) {
      url = ENDPOINT_URL.worldLinePayments;
      params = {
        ...params,
        hostedCheckoutSpecificInput: {
          returnUrl,
          locale,
          cardPaymentMethodSpecificInput: {
            groupCards: worldLineGroupCards
          }
        }
      }
    } else if (paymentProvider == PaymentProvider.STRIPE) {
      url = ENDPOINT_URL.stripePayments;
    } else {
      throw new Error(`Can't recognize payment provider = '${paymentProvider}'`);
    }

    return await new Http().post(url, {...params}, name);
  };

  const createStripeFailedTransaction = async (
    amount: number,
    currency: string,
    paymentIntentId: string,
    failureReason?: string
  ) =>
    await new Http().post(
      ENDPOINT_URL.createStripeFailedTransaction,
      { amount, currency, paymentIntentId, failureReason },
      name
    );

  const getPaymentStatus = async (paymentIntentId: string) => {
    return await new Http().get(ENDPOINT_URL.getPaymentStatus(paymentIntentId), name);
  }

  return {
    getPaymentStatus,
    createPayment,
    createStripeFailedTransaction
  };
}
