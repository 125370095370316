import React from 'react';
import { useTranslation } from 'react-i18next';

import Image from '../Image';
import Card from '../Card';

import { FA_ICONS } from '../../constants';
import { capitalizeFirstLetter } from '../../helpers';

import styles from './styles.module.css';
import { HardwareStatus } from '../../models/evse';

interface StatusIndicatorProps {
  status: string;
  numberOfEvses?: number;
}

const StatusIndicator = ({ status, numberOfEvses }: StatusIndicatorProps) => {
  const { t } = useTranslation();
  let icon;
  let text;

  switch (status) {
    case HardwareStatus.available:
      icon = FA_ICONS.faCircleCheckLarge;
      text = 'app.charge_point.status_available';
      break;
    case HardwareStatus.charging:
      text = 'app.charge_point.status_charging'; break;
    case HardwareStatus.preparing:
      icon = FA_ICONS.faChargingBattery;
      text = 'app.charge_point.status_preparing';
      break;
    case HardwareStatus.faulted:
      text = 'app.charge_point.status_faulted'; break;
    case HardwareStatus.reserved:
      text = 'app.charge_point.status_reserved'; break;
    case HardwareStatus.suspendedEV:
      text = 'app.charge_point.status_suspended_ev'; break;
    case HardwareStatus.suspendedEVSE:
      text = 'app.charge_point.status_suspended_evse'; break;
    case HardwareStatus.finishing:
      text = 'app.charge_point.status_finishing'; break;
    case HardwareStatus.occupied:
      text = 'app.charge_point.status_occupied'; break;
    case HardwareStatus.unavailable:
      text = 'app.charge_point.status_unavailable'; break;
    default:
      text = undefined;
      icon = undefined;
  }

  const shouldShowTranslation = t(text as HardwareStatus).length > 0;

  return (
    <Card className={styles.container}>
      <section className={styles.status}>
        <Image src={icon} />
        <span className={styles.label}>{t('app.charge_point.status.label')}</span>
      </section>
      <span className={styles.bold}>
        {numberOfEvses ? `${numberOfEvses}` : null}{' '}
        {shouldShowTranslation ? t(text as HardwareStatus) : `${capitalizeFirstLetter(status)}`}
      </span>
    </Card>
  );
};

export default StatusIndicator;
