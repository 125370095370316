import React from 'react';

interface IconProps {
  src: string;
  className?: string;
}

const Image = ({ src, className }: IconProps) => {
  return <img src={src} alt="" className={className} />;
};

export default Image;
