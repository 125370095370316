import Image from "../Image";

import styles from "./styles.module.css";

interface DownloadCardProps {
  href: string;
  iconSrc: string;
  label: string;
  store: string;
}

const DownloadCard = ({ href, iconSrc, label, store }: DownloadCardProps) => {
  return (
    <a
      className={styles.container}
      href={href}
      target="_blank"
      rel="noreferrer">
      <span>
        <Image src={iconSrc} />
      </span>
      <section className={styles.label}>
        <span>{label}</span>
        <span className={styles.labelBold}>{store}</span>
      </section>
    </a>
  );
};

export default DownloadCard;
