import { useLocation, useNavigate } from 'react-router-dom';
import { LANGUAGE_KEY, LocalStorage } from '../../storage';

import styles from './styles.module.css';
import Header from '../../components/Header';
import React from 'react';
import { useTranslation } from 'react-i18next';

const PricingDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const locale = LocalStorage.getItem(LANGUAGE_KEY) as string | undefined;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const [language, region] = locale.split('_');
  const location = useLocation();
  const additionalInfo = location.state?.description[language ?? 'en'];

  const onBack = () => navigate(-1);

  return (
    <section className={styles.container}>
      <Header showBackButton label={t('app.price_details.title')} onClick={onBack} />
      {additionalInfo ? <section dangerouslySetInnerHTML={{ __html: additionalInfo ?? undefined }} /> : null}
    </section>
  );
};
export default PricingDetails;
