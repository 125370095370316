import React from 'react';

import Image from '../Image';

import styles from './styles.module.css';

interface LogoProps {
  logoUrl: string | undefined;
}

const Logo = ({ logoUrl }: LogoProps) => {
  if (!logoUrl) {
    return;
  }

  return (
    <section className={styles.container}>
      <Image src={logoUrl} className={styles.logo} />
    </section>
  );
};

export default Logo;
