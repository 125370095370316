import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import { handleAndMonitorError } from '../helpers';
import { ENDPOINT_URL } from 'src/constants/endpoints';
import { Http } from '../http';

export const defaultLanguageCode = 'en';

i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  fallbackLng: defaultLanguageCode,
  resources: {},
  partialBundledLanguages: true
});

async function loadTranslations(languageCode: string) {
  const apiLangTranslations = await new Http().get(ENDPOINT_URL.translations(languageCode));
  // Add to the i18n module
  i18n.addResourceBundle(
    languageCode,
    'translation',
    JSON.parse(JSON.stringify(apiLangTranslations.data.data)),
    undefined,
    true
  );
}

async function addTranslation(languageCode: string): Promise<string> {
  try {
    await loadTranslations(languageCode);
    return languageCode;
  } catch (e) {
    handleAndMonitorError(`Error while trying to retrieve translations for ${languageCode} language code: ${e}`);
    await loadTranslations(defaultLanguageCode);
    return defaultLanguageCode;
  }
}

export default i18n;
export { addTranslation };
