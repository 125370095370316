import React from 'react';
import { useTranslation } from 'react-i18next';

import useConfig from '../../hooks/useConfig';

import styles from './styles.module.css';

const BottomNavigation = () => {
  const { t } = useTranslation();
  const { bottomNavigation } = useConfig();

  return (
    <section>
      <hr className={styles.line} />
      <section className={styles['bottom_navigation_links__flex-container']}>
          {bottomNavigation.map((link, index) => (
              <a href={link.href} key={index} target="_blank" rel="noreferrer" className={styles['bottom_navigation_links__flex-item']}>{link.title}</a>
          ))}
      </section>
    </section>
  );
};

export default BottomNavigation;
