import { DetailsSectionProps, TariffType } from '../../../models/evse';
import DetailItem from './DetailItem';
import { useTranslation } from 'react-i18next';
import { CURRENCY_SYMBOL } from '../../../constants/currencySymbol';

import styles from './styles.module.css';

type TariffSelectorProps = Pick<
  DetailsSectionProps,
  | 'type'
  | 'deposit'
  | 'minPrice'
  | 'currency'
  | 'price'
  | 'connectionFee'
  | 'connectionFeeMinimumSessionDuration'
  | 'connectionFeeMinimumSessionEnergy'
  | 'pricePerPeriod'
  | 'pricePeriodInMinutes'
  | 'durationFeeGracePeriod'
  | 'dayIdleFeePerMinute'
  | 'idleFeeGracePeriodMinutes'
  | 'idleFeePerMinute'
  | 'dayPricePerKwh'
  | 'dayPricePerPeriod'
  | 'nightIdleFeePerMinute'
  | 'nightPricePerPeriod'
  | 'nightPricePerKwh'
  | 'dayTariffStart'
  | 'nightTariffStart'
>;

const TariffSelector = ({
  type,
  deposit,
  minPrice,
  currency,
  price,
  connectionFee,
  connectionFeeMinimumSessionDuration,
  connectionFeeMinimumSessionEnergy,
  pricePerPeriod,
  pricePeriodInMinutes,
  durationFeeGracePeriod,
  dayTariffStart,
  dayPricePerKwh,
  dayPricePerPeriod,
  dayIdleFeePerMinute,
  nightIdleFeePerMinute,
  nightTariffStart,
  nightPricePerPeriod,
  nightPricePerKwh,
  idleFeeGracePeriodMinutes,
  idleFeePerMinute
}: TariffSelectorProps) => {
  const { t } = useTranslation();
  const displayAmount = (value: number): string => {
    return value ? value.toFixed(2) : '0.00';
  };

  const displayData = (value: number): string => {
    return `${CURRENCY_SYMBOL[currency]} ${displayAmount(value)}`;
  };

  switch (type) {
    case TariffType.DURATION_ENERGY:
      return (
        <>
          {deposit ? (
            <DetailItem translations={[t('app.tariff.pre_auth_amount')]} tariffInfo={[displayData(deposit)]} />
          ) : null}
          {price ? (
            <DetailItem translations={[t('app.tariff.price_per_kwh')]} tariffInfo={[displayData(price)]} />
          ) : null}
          {minPrice ? (
            <DetailItem separator translations={[t('app.tariff.min_price')]} tariffInfo={[displayData(minPrice)]} />
          ) : null}
          {connectionFee && connectionFee > 0 ? (
            <DetailItem
              translations={[
                t(' app.tariff.connection_fee'),
                t(' app.tariff.connection_fee_min_session_duration'),
                t(' app.tariff.connection_fee_min_session_energy')
              ]}
              tariffInfo={[
                displayData(connectionFee),
                `${connectionFeeMinimumSessionDuration} mins`,
                `${connectionFeeMinimumSessionEnergy} kWh`
              ]}
            />
          ) : null}
          {pricePerPeriod && pricePerPeriod > 0 ? (
            <DetailItem
              // TODO: Figure out why we need space for these translations
              separator
              translations={[
                t(' app.tariff.fee_per_time_period'),
                t(' app.tariff.duration_fee_time_period'),
                t(' app.tariff.duration_fee_grace_period')
              ]}
              tariffInfo={[
                displayData(pricePerPeriod),
                `${pricePeriodInMinutes} mins`,
                `${durationFeeGracePeriod} mins`
              ]}
            />
          ) : null}
          {idleFeeGracePeriodMinutes && idleFeeGracePeriodMinutes > 0 ? (
            <DetailItem
              translations={[t('app.tariff.idle_fee_per_minute'), t('app.tariff.idle_fee_grace_period')]}
              tariffInfo={[displayData(idleFeePerMinute), `${idleFeeGracePeriodMinutes} min`]}
            />
          ) : null}
        </>
      );
    case TariffType.DURATION_ENERGY_TOD:
      return (
        <>
          <section className={styles.dayNightTariff}>
            {deposit && deposit > 0 ? (
              <DetailItem
                separator
                translations={[t('app.tariff.pre_auth_amount')]}
                tariffInfo={[displayData(deposit)]}
              />
            ) : null}
            {dayPricePerKwh && dayPricePerKwh > 0 ? (
              <DetailItem
                translations={[
                  `${t('app.tariff.day_starts_at')} (${dayTariffStart?.substring(0, 5)}-${nightTariffStart?.substring(0, 5)})`,
                  t('app.tariff.price_per_kwh')
                ]}
                tariffInfo={[null, displayData(dayPricePerKwh)]}
              />
            ) : null}
            {nightPricePerKwh && nightPricePerKwh > 0 ? (
              <DetailItem
                translations={[
                  `${t('app.tariff.night_starts_at')} (${nightTariffStart?.substring(0, 5)}-${dayTariffStart?.substring(0, 5)})`,
                  t('app.tariff.price_per_kwh')
                ]}
                tariffInfo={[null, displayData(nightPricePerKwh)]}
              />
            ) : null}
          </section>
          {minPrice ? (
            <DetailItem separator translations={[t('app.tariff.min_price')]} tariffInfo={[displayData(minPrice)]} />
          ) : null}
          {connectionFee && connectionFee > 0 ? (
            <DetailItem
              separator
              translations={[
                t(' app.tariff.connection_fee'),
                t(' app.tariff.connection_fee_min_session_duration'),
                t(' app.tariff.connection_fee_min_session_energy')
              ]}
              tariffInfo={[
                displayData(connectionFee),
                `${connectionFeeMinimumSessionDuration} mins`,
                `${connectionFeeMinimumSessionEnergy} kWh`
              ]}
            />
          ) : null}
          {dayPricePerPeriod || nightPricePerPeriod ? (
            <DetailItem
              showNullish
              separator
              translations={[
                t('app.tariff.day_fee_per_time_period'),
                t('app.tariff.night_fee_per_time_period'),
                t(' app.tariff.duration_fee_time_period'),
                t(' app.tariff.duration_fee_grace_period')
              ]}
              // If values are undefined, translations will also not render
              tariffInfo={[
                displayData(dayPricePerPeriod),
                displayData(nightPricePerPeriod),
                `${pricePeriodInMinutes} min`,
                `${durationFeeGracePeriod} min`
              ]}
            />
          ) : null}
          {dayIdleFeePerMinute || nightIdleFeePerMinute ? (
            <DetailItem
              showNullish
              translations={[
                t('app.tariff.day_idle_fee_per_minute'),
                t('app.tariff.night_idle_fee_per_minute'),
                t('app.tariff.idle_fee_grace_period')
              ]}
              tariffInfo={[
                displayData(dayIdleFeePerMinute),
                displayData(nightIdleFeePerMinute),
                `${idleFeeGracePeriodMinutes} min`
              ]}
            />
          ) : null}
        </>
      );
    default:
      return null;
  }
};

export default TariffSelector;
