import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import Header from '../../components/Header';
import Button, { ButtonType } from '../../components/Button';
import ChargePointCard from '../../components/ChargePointContainer/ChargePointCard';
import Image from '../../components/Image';
import Notification from '../../components/Notification';
import Loader from '../../components/Loader';

// Constants
import { FA_ICONS, GIF } from '../../constants';
import { CONNECTOR, CONNECTOR_NAME } from '../../constants/connector';
import { ENDPOINT_URL } from '../../constants/endpoints';
import { Payment } from '../../models/payment';
import { LocalStorage, PAYMENT_KEY } from '../../storage';
import { CURRENCY_SYMBOL } from '../../constants/currencySymbol';
import { ROUTE_NAMES } from '../../router/route-names';
import { SessionStatus, SessionSummary as Summary } from '../../models/session';

import { convertWattToKilowatt, formatDate, handleAndMonitorError } from '../../helpers';

import styles from './styles.module.css';
import useConfig from '../../hooks/useConfig';
import { DisplayMode } from '../../constants/display-mode';
import useSession from '../../hooks/useSession';
import { buildTenantUrl, checkPathnameIncludes } from '../../helpers/routing';

const INTERVAL_PERIOD = 10000;

const SessionSummary = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [notificationMessage, setNotificationMessage] = useState(t('app.session_summary.generating.receipt.message'));
  const [showNotification, setShowNotification] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const payment = LocalStorage.getItem(PAYMENT_KEY) as Payment | undefined;
  const location = useLocation();
  const [summary, setSummary] = useState<Summary>();
  const [loading, setLoading] = useState(true);
  const [downloadUrl, setDownloadUrl] = useState<string>();
  const { name, displayMode } = useConfig();
  const { getFinishedSession, getReceipt } = useSession();

  useEffect(() => {
    if (!payment) {
      throw new Error('Missing payment details');
    }

    if (!location?.state?.pin) {
      navigate(`../../${ROUTE_NAMES.enterPinCode}`, {
        state: {
          summary: true
        }
      });
      return;
    }

    const retrieveSummary = async () => {
      let sessionId = location?.state?.sessionId ?? payment?.sessionId;

      try {
        setShowNotification(true);
        const sessionResponse = await getFinishedSession(payment, sessionId);
        const session: Summary = sessionResponse?.data?.data;

        if (sessionResponse.status !== 200 || !session) {
          setError(t('app.session_summary.error'));
          setLoading(false);
          return;
        }

        switch (session.status) {
          case SessionStatus.pending:
          case SessionStatus.active:
            // stay on the same page if session is pending or still active
            break;
          case SessionStatus.finished:
            setSummary(session);
            if (session.receiptId) {
              const receiptResponse = await getReceipt(session);
              setDownloadUrl(receiptResponse?.data?.data?.downloadUrl);
              setNotificationMessage(t('app.session_summary.generated.receipt.message'));
              setShowNotification(true);
            }
            setLoading(false);
            return;
          default:
            navigate(`../${ROUTE_NAMES.chargingSessionFailed}`, {
              state: {
                status: session.status
              },
              replace: true
            });
            break;
        }
      } catch (e: any) {
        handleAndMonitorError(
          `Error while trying to get session details from ${ENDPOINT_URL.sessionFinished(
            payment.chargePointId,
            payment.evseId,
            sessionId
          )}: ${e?.response?.data?.error}`
        );
        setError(e?.response?.data?.error ?? t('app.session_summary.error'));
        setLoading(false);
      }
    };

    retrieveSummary();
    const interval = setInterval(retrieveSummary, INTERVAL_PERIOD);

    return () => clearInterval(interval);
  }, []);

  if (loading) {
    return <Loader />;
  }

  const formatDuration = (duration: string): string => {
    // expected format "hh:mm:ss"
    if (!duration) {
      return '';
    }

    const [hours, minutes, seconds] = duration?.split(' ')[0]?.split(':');

    const empty = '00';
    let result = '';

    if (hours && hours !== empty) {
      result += `${hours}h `;
    }

    if (minutes && minutes !== empty) {
      result += `${minutes}m `;
    }

    if (seconds && seconds !== empty) {
      result += `${seconds}s `;
    }

    return result;
  };

  const displayAmount = (value: number): string => {
    return value ? value.toFixed(2) : '0.00';
  };

  const displayAmountData = (currency: string, value: number): string => {
    return `${CURRENCY_SYMBOL[currency]} ${displayAmount(value)}`;
  };

  const handleBackButton = () => {
    if (displayMode === DisplayMode.Search) {
      navigate(`../../${ROUTE_NAMES.selectEvse}`);
    } else {
      if (checkPathnameIncludes('tenant')) {
        navigate(`../../${ROUTE_NAMES.selectEvse}`);
      } else {
        navigate(
          buildTenantUrl({
            pathPrefix: '../..',
            tenant: name,
            routeName: ROUTE_NAMES.selectEvse
          })
        );
      }
    }
  };

  return (
    <section className="page-container">
      <Header showBackButton label={t('app.session_summary.header')} onClick={handleBackButton} />
      {summary && (
        <>
          <ChargePointCard name={summary.name} location={summary.address} className={styles.chargingPoint} />
          <section className={styles.energy}>
            <section className={`blue-card ${styles.energyDetails}`}>
              <section>
                <Image src={FA_ICONS.faLocation} />
              </section>
              <section className={styles.data}>
                <span className={`bold ${styles.header}`}>{`${convertWattToKilowatt(summary.energy)} kWh`}</span>
                <span>{t('app.session_summary.details.energy.label')}</span>
              </section>
            </section>
            <section className={`blue-card ${styles.connector}`}>
              <Image src={CONNECTOR[summary.connectorType]} />
              <span className={styles.connectorType}>{CONNECTOR_NAME[summary.connectorType]}</span>
            </section>
          </section>

          <section className={`blue-card ${styles.durationSection}`}>
            <section className={styles.durationRow}>
              <section>
                <Image src={FA_ICONS.faClock} />
              </section>
              <section className={styles.data}>
                <span className={`bold ${styles.header}`}>{formatDuration(summary.duration)}</span>
                <span>{t('app.session_summary.details.duration.label')}</span>
              </section>
            </section>
            <section className={styles.durationRow}>
              <section>
                <Image src={FA_ICONS.faCoins} />
              </section>
              <section className={styles.data}>
                <span className={`bold ${styles.header}`}>{displayAmountData(summary.currency, summary.amount)}</span>
                <span>{t('app.session_summary.details.amount.label')}</span>
              </section>
            </section>
            <section></section>
          </section>

          <section className={`blue-card ${styles.times}`}>
            <section className={styles.timesRow}>
              <span>{t('app.session_summary.details.started.label')}</span>
              <span className="bold">{formatDate(summary.startedAt)}</span>
            </section>
            <section className={styles.timesRow}>
              <span>{t('app.session_summary.details.finished.label')}</span>
              <span className="bold">{formatDate(summary.stoppedAt)}</span>
            </section>
          </section>
          <br />
          <a href={downloadUrl} download className={styles.url}>
            <Button
              label={
                downloadUrl
                  ? t('app.session_summary.download.receipt.button')
                  : t('app.session_summary.generate.receipt.button')
              }
              type={ButtonType.primary}
              disabled={!downloadUrl}
              icon={downloadUrl ? undefined : GIF.waveLoaderWhite}
              classNameIcon={downloadUrl ? '' : styles.icon}
            />
          </a>
        </>
      )}
      <br />
      {error && <span className={'general-error'}>{error}</span>}
      {showNotification && (
        <Notification
          type={'info'}
          header={notificationMessage}
          show={true}
          useTimeout={false}
          position={'bottom'}
          showClose={!!downloadUrl}
        />
      )}
    </section>
  );
};

export default SessionSummary;
