import buildAppRootPath from './build-app-root-path';

export const ROUTE_NAMES = {
  root: buildAppRootPath(),
  chooseLanguage: 'choose-language',
  chargePoint: 'charge-point',
  selectEvse: 'evses',
  evse: 'evse',
  search: 'search',
  pricePrognosis: 'price-prognosis',
  priceDetails: 'price-details',
  choosePaymentMethod: 'payment',
  confirmPayment: 'confirm-payment',
  createPinCode: 'create-pin-code',
  howToSetPinCode: 'how-to-set-pin-code',
  chargingSession: 'session',
  chargingSessionFailed: 'session-failed',
  enterPinCode: 'enter-pin-code',
  resetPinCode: 'reset-pin-code',
  enterLast4Digits: 'enter-last-4',
  sessionSummary: 'summary',
  reEnterCardDetails: 're-enter-card-details',
  sessionStart: 'session-start'
};
