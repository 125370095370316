import { useLayoutEffect, useState } from 'react';

import { Evse, HardwareStatus } from '../../models/evse';
import { Http } from '../../http';
import { ENDPOINT_URL } from '../../constants/endpoints';
import { ChargePoint } from '../../models/chargePoint';
import { handleAndMonitorError } from '../../helpers';
import useConfig from '../useConfig';
import { CHARGE_POINT_KEY, LocalStorage } from '../../storage';
import { ROUTE_NAMES } from '../../router/route-names';
import { formatEvsePhysicalReference } from '../../helpers/charge-point-validate';

const useChargePointData = () => {
  const [tenant, setTenant] = useState<string>();
  const [chargePoint, setChargePoint] = useState<ChargePoint>();
  const [evses, setEvses] = useState<Evse[]>([]);
  const [availableCount, setAvailableCount] = useState<number>(0);
  const [error, setError] = useState(false);
  const { name } = useConfig();

  useLayoutEffect(() => {
    const dataFetch = async () => {
      const path = window.location.pathname.split('/');
      const evseIndex = path.findIndex(element => element === 'evse');
      const startSessionIndex = path.findIndex(element => element === ROUTE_NAMES.sessionStart);
      const evsePhysicalReference = evseIndex < 0 ? path[startSessionIndex + 1] : path[evseIndex + 1];
      const tenantIndex = path.findIndex(element => element === 'tenant');
      const tenantName = path[tenantIndex + 1];

      const setEvseData = async ({ shouldTryeMI3 }: { shouldTryeMI3: boolean }) => {
        const response = await new Http().get(
          ENDPOINT_URL.chargePoint(
            shouldTryeMI3 ? formatEvsePhysicalReference(evsePhysicalReference) : evsePhysicalReference
          ),
          name
        );
        const data = response.data.data;

        if (!data) return;

        setChargePoint(data.chargePoint);
        setTenant(tenantName ?? response.headers['tenant']);
        LocalStorage.setItem(CHARGE_POINT_KEY, data.chargePoint);

        let count = 0;
        const evsesToDisplay: Evse[] = data.evses.filter(evse => {
          if (evse.hardwareStatus) {
            if (evse.hardwareStatus === HardwareStatus.available) {
              count++;
            }
            return evse;
          }
        });

        setEvses(evsesToDisplay);
        setAvailableCount(count);
      };

      try {
        if (!evsePhysicalReference || evsePhysicalReference === 'undefined') {
          throw new Error('missing evsePhysicalReference details');
        }
        await setEvseData({ shouldTryeMI3: false });
      } catch (e) {
        try {
          await setEvseData({ shouldTryeMI3: true });
        } catch (nextError) {
          setError(true);
          handleAndMonitorError(
            `Error while trying to retrieve charge point details from ${ENDPOINT_URL.chargePoint(evsePhysicalReference ?? '')}: ${nextError}`
          );
        }
      }
    };

    dataFetch().then();
  }, []);

  return { tenant, chargePoint, evses, availableCount, error };
};

export default useChargePointData;
