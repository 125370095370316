import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

// Components
import Header from '../../components/Header';
import Button, { ButtonType } from '../../components/Button';

import { ROUTE_NAMES } from '../../router/route-names';

import styles from './styles.module.css';
import { Payment } from '../../models/payment';
import { LocalStorage, PAYMENT_KEY } from '../../storage';

const HowToSetPinCode = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const payment = LocalStorage.getItem(PAYMENT_KEY) as Payment | undefined;

  return (
    <section className="page-container">
      <Header showBackButton={false} label={t('app.how_to_set_pin.header')} />
      <section className={styles.container}>
        <main>
          <section className={styles.section}>
            <header className={`${styles.header} ${styles.main} bold`}>
              {t('app.how_to_set_pin.security.measure.header')}
            </header>
            <section className={`${styles.description} ${styles.mainDescription}`}>
              {t('app.how_to_set_pin.security.measure.description')}
            </section>
          </section>
          <section className={styles.section}>
            <header className={`${styles.header} bold`}>{t('app.how_to_set_pin.setting.header')}</header>
            <section className={styles.description}>{t('app.how_to_set_pin.setting.description')}</section>
          </section>
          <section className={styles.section}>
            <header className={`${styles.header} bold`}>{t('app.how_to_set_pin.forgetting.header')}</header>
            <section className={styles.description}>{t('app.how_to_set_pin.forgetting.description')}</section>
          </section>
        </main>
        <section className={styles.buttonContainer}>
          <Button
            label={t('app.how_to_set_pin.button')}
            type={ButtonType.primary}
            onClick={() =>
              navigate(
                `../${ROUTE_NAMES.choosePaymentMethod}/${payment?.paymentIntentId ?? location?.state?.paymentIntentId}/${ROUTE_NAMES.createPinCode}`,
                {
                  state: {
                    paymentIntentId: location?.state?.paymentIntentId
                  }
                }
              )
            }
          />
        </section>
      </section>
    </section>
  );
};

export default HowToSetPinCode;
