import React from 'react';

import { CircleFlag } from 'react-circle-flags';

interface LanguageFlagProps {
  code: string;
}

const LanguageFlag = ({ code }: LanguageFlagProps) => {
  let countryCode = code;
  const [language, region] = countryCode.split('_');

  if (!region) return null;

  return <CircleFlag countryCode={region.toLowerCase()} height="32" />;
};

export default LanguageFlag;
