export const CONNECTOR = {
  ac: '/assets/connectors/ac.svg',
  avcon: '/assets/connectors/avcon.svg',
  cable: '/assets/connectors/cable.svg',
  catarc: '/assets/connectors/catarc.svg',
  cee16: '/assets/connectors/cee16.svg',
  cee32: '/assets/connectors/cee32.svg',
  chademo: '/assets/connectors/chademo.svg',
  combo1: '/assets/connectors/combo1.svg',
  combo2: '/assets/connectors/combo2.svg',
  dc: '/assets/connectors/dc.svg',
  'gb-t-ac': '/assets/connectors/gbtac.svg',
  inductive: '/assets/connectors/inductive.svg',
  'nema-5-20': '/assets/connectors/nema5.svg',
  schuko: '/assets/connectors/schuko.svg',
  supercharger: '/assets/connectors/supercharger.svg',
  type1: '/assets/connectors/type1.svg',
  type2: '/assets/connectors/type2.svg',
  type3: '/assets/connectors/type3.svg',
  'type-e-french': '/assets/connectors/typeefrench.svg',
  'type-g-british': '/assets/connectors/typegbritish.svg',
  'type-j-swiss': '/assets/connectors/typejswiss.svg',
  unoccupied: '/assets/connectors/unoccupied.svg',
  ccs1: '/assets/connectors/ccs1.svg',
  ccs2: '/assets/connectors/ccs2.svg',
  j1772: '/assets/connectors/j1772.svg',
  'gb-t-dc': '/assets/connectors/dc.svg',
  nacs: '/assets/connectors/nacs.svg'
};

export const CONNECTOR_NAME = {
  ac: 'AC',
  avcon: 'Avcon',
  cable: 'Cable',
  catarc: 'Catarc',
  cee16: 'Cee16',
  cee32: 'Cee32',
  chademo: 'Chademo',
  combo1: 'Combo 1',
  combo2: 'Combo 2',
  dc: 'DC',
  'gb-t-ac': 'GBT-AC',
  inductive: 'Inductive',
  'nema-5-20': 'Nema-5-20',
  schuko: 'Schuko',
  supercharger: 'Supercharger',
  type1: 'Type 1',
  type2: 'Type 2',
  type3: 'Type 3',
  'type-e-french': 'Type-E-french',
  'type-g-british': 'Type-G-british',
  'type-j-swiss': 'Type-J-swiss',
  unoccupied: 'Unoccupied',
  ccs1: 'Ccs1',
  ccs2: 'Ccs2',
  j1772: 'J1772',
  'gb-t-dc': 'GBT-DC'
};
