import React from 'react';

import Image from '../Image';
import useConfig from '../../hooks/useConfig';
import { FA_ICONS } from '../../constants';

import styles from './styles.module.css';

interface HeaderPros {
  showBackButton: boolean;
  label?: string;
  onClick?: () => void;
}

const Header = ({ showBackButton, label, onClick }: HeaderPros) => {
  const { contactPhoneNumber } = useConfig();

  return (
    <header className={`${styles.header} ${showBackButton ? '' : styles.center}`}>
      <section>
        {showBackButton && (
          <span className={`blue-card ${styles.button}`} onClick={onClick}>
            {showBackButton && <Image src={FA_ICONS.faArrowLeft} />}
          </span>
        )}
        {label && <span>{label}</span>}
      </section>
      {contactPhoneNumber && contactPhoneNumber.trim().length && (
        <span className={`blue-card ${styles.button} ${styles.call}`}>
          <a href={`tel://${contactPhoneNumber}`}>
            <Image src={FA_ICONS.faCall} />
          </a>
        </span>
      )}
    </header>
  );
};

export default Header;
