import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import Button, { ButtonType } from '../Button';
import Image from '../Image';
import StatusIndicator from '../StatusIndicator';
import DetailsSection from './DetailsSection';

// Constants and models
import { FA_ICONS } from '../../constants';
import { CONNECTOR, CONNECTOR_NAME } from '../../constants/connector';
import { CURRENCY_SYMBOL } from '../../constants/currencySymbol';
import { Evse as EvseModel, HardwareStatus } from '../../models/evse';

import { convertWattToKilowatt } from '../../helpers';

import styles from './styles.module.css';

interface EvseProps {
  tenant?: string;
  chargePointId: number;
  evse: EvseModel;
  numberOfEvses: number;
}

const Evse = ({ tenant, chargePointId, evse, numberOfEvses }: EvseProps) => {
  const { t } = useTranslation();
  const [showDetailsSection, setShowDetailsSection] = useState(numberOfEvses === 1);

  const tariffData = (): string => {
    return `${CURRENCY_SYMBOL[evse.tariff.currency]} ${evse.tariff.pricing.pricePerKwh.toFixed(2)} per kWh`;
  };

  const tariffDayData = (): string | null => {
    if (!evse.tariff.pricing?.dayPricePerKwh) return null;
    return `${CURRENCY_SYMBOL[evse.tariff.currency]} ${evse.tariff.pricing?.dayPricePerKwh.toFixed(2)} per kWh`;
  };

  const tariffNightData = (): string | null => {
    if (!evse.tariff.pricing?.nightPricePerKwh) return null;
    return `${CURRENCY_SYMBOL[evse.tariff.currency]} ${evse.tariff.pricing?.nightPricePerKwh.toFixed(2)} per kWh`;
  };

  const getConnectors = (): string[] => {
    return evse.connectors.map(connector => connector.type);
  };

  return (
    <>
      <section className={styles.container} onClick={() => setShowDetailsSection(previousState => !previousState)}>
        <section className={styles.detailsRow}>
          <section className={`blue-card ${styles.details}`}>
            <aside>
              <Image src={FA_ICONS.faLocation} />
            </aside>
            <section className={styles.data}>
              <span className={`bold ${styles.energy}`}>{`${convertWattToKilowatt(
                evse.powerOptions.maxPower
              )} kW ${evse.currentType.toUpperCase()}`}</span>
              <span className={styles.id}>ID: {evse.physicalReference}</span>
            </section>
          </section>
          <section className={`blue-card ${styles.connectorContainer}`}>
            {getConnectors().map(type => {
              return (
                <section className={styles.connector}>
                  <Image src={CONNECTOR[type]} />
                  <span className={styles.connectorType}>{CONNECTOR_NAME[type]}</span>
                </section>
              );
            })}
          </section>
        </section>
        <StatusIndicator status={evse.hardwareStatus} />
        {evse.tariff.pricing.pricePerKwh ? (
          <section className={`blue-card ${styles.tariffRow}`}>
            <section className={styles.tariffRow}>
              <Image src={FA_ICONS.faLightMedal} />
              <section className={styles.tariffData}>{tariffData()}</section>
            </section>

            {!showDetailsSection && (
              <section className={styles.action}>
                <Button
                  label={
                    evse.hardwareStatus === HardwareStatus.available
                      ? t('app.select_evse.select.button')
                      : t('app.select_evse.view.button')
                  }
                  type={ButtonType.primary}
                  className={styles.evseAction}
                />
              </section>
            )}
          </section>
        ) : null}
        {evse.tariff.pricing.nightPricePerKwh && evse.tariff.pricing.dayPricePerKwh ? (
          <section className={styles.detailsRow}>
            <section className={`blue-card ${styles.tariffRow}`}>
              <section className={showDetailsSection ? styles.tariffRow : styles.detailsCol}>
                <section className={styles.tariffRow}>
                  <Image src={FA_ICONS.faSun} />
                  <section className={styles.tariffData}>{tariffDayData()}</section>
                </section>
                <section className={styles.tariffRow}>
                  <Image src={FA_ICONS.faMoon} />
                  <section className={styles.tariffData}>{tariffNightData()}</section>
                </section>
              </section>
              {!showDetailsSection && (
                <section className={styles.action}>
                  <Button
                    label={
                      evse.hardwareStatus === HardwareStatus.available
                        ? t('app.select_evse.select.button')
                        : t('app.select_evse.view.button')
                    }
                    type={ButtonType.primary}
                    className={styles.evseAction}
                  />
                </section>
              )}
            </section>
          </section>
        ) : null}
      </section>
      {/* Hidden section with Details which shows when Select/View buttons are clicked */}
      {showDetailsSection && (
        <DetailsSection
          additionalInfo={evse.tariff.additionalInformation}
          description={evse.tariff.description}
          dayIdleFeePerMinute={evse.tariff.pricing?.dayIdleFeePerMinute}
          dayTariffStart={evse.tariff.dayTariffStart}
          nightIdleFeePerMinute={evse.tariff.pricing.nightIdleFeePerMinute}
          nightTariffStart={evse.tariff.nightTariffStart}
          nightPricePerKwh={evse.tariff.pricing.nightPricePerKwh}
          nightPricePerPeriod={evse.tariff.pricing.nightPricePerPeriod}
          dayPricePerKwh={evse.tariff.pricing.dayPricePerKwh}
          dayPricePerPeriod={evse.tariff.pricing.dayPricePerPeriod}
          status={evse.hardwareStatus}
          currency={evse.tariff.currency}
          price={evse.tariff.pricing.pricePerKwh}
          startFee={evse.tariff.pricing.connectionFee}
          minPrice={evse.tariff.pricing.minPrice}
          deposit={evse.tariff.restrictions.adHocPreAuthorizeAmount}
          connectionFee={evse.tariff.pricing.connectionFee}
          connectionFeeMinimumSessionDuration={evse.tariff.pricing.connectionFeeMinimumSessionDuration}
          tenant={tenant ?? ''}
          chargePointId={chargePointId}
          evseId={evse.id}
          evsePhysicalReference={evse.physicalReference}
          pricePerPeriod={evse.tariff.pricing.pricePerPeriod}
          connectionFeeMinimumSessionEnergy={evse.tariff.pricing.connectionFeeMinimumSessionEnergy}
          connectorId={evse.connectors[0].id}
          pricePerKwh={evse.tariff.pricing.pricePerKwh}
          pricePeriodInMinutes={evse.tariff.pricing.pricePeriodInMinutes}
          durationFeeGracePeriod={evse.tariff.pricing.durationFeeGracePeriod}
          idleFeeGracePeriodMinutes={evse.tariff.pricing.idleFeeGracePeriodMinutes}
          idleFeePerMinute={evse.tariff.pricing.idleFeePerMinute}
          type={evse.tariff.type}
        />
      )}
    </>
  );
};

export default Evse;
