import styles from './styles.module.css';
import Separator from '../../common/Seperator';

interface DetailItemProps {
  title?: string;
  tariffInfo?: any;
  translations: string[];
  separator?: boolean;
  showNullish?: boolean;
}

const DetailItem = ({ translations, tariffInfo, separator, showNullish }: DetailItemProps) => {
  const showRow = tariffInfo && tariffInfo.length > 0;

  return (
    <section className={styles.tariffCol}>
      {showRow &&
        tariffInfo.map((t: string | number | null, index: number) => {
          const extractedNumber = typeof t === 'string' && t.replace(/\D/g, '');
          if (Number(extractedNumber) > 0 || (typeof t === 'number' && t > 0) || t === null || showNullish) {
            return (
              <section className={styles.tariffRow}>
                <span>{translations[index]}</span>
                <span className={styles.bold}>{t}</span>
              </section>
            );
          } else return null;
        })}
      {separator && <Separator />}
    </section>
  );
};

export default DetailItem;
