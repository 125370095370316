import React from 'react';

import Image from '../Image';
import { FA_ICONS } from '../../constants';

import styles from './styles.module.css';

interface TextButtonProps {
  label: string;
  onClick: () => void;
}

const TextButton = ({ label, onClick }: TextButtonProps) => {
  return (
    <section className={styles.container} onClick={onClick}>
      <span className={`bold ${styles.label}`}>{label}</span>
      <span className={styles.icon}>
        <Image src={FA_ICONS.faAngleRightBlue} />
      </span>
    </section>
  );
};

export default TextButton;
