import React from 'react';
import { useTranslation } from 'react-i18next';

import DownloadCard from '../DownloadCard';
import { FA_ICONS } from '../../constants';

import styles from './styles.module.css';

interface DownloadAppsProps {
  appStoreUrl: string | null;
  googleStoreUrl: string | null;
}

const DownloadApps = ({ appStoreUrl, googleStoreUrl }: DownloadAppsProps) => {
  const { t } = useTranslation();

  return (
    <section className={styles.container}>
      <span className={styles.bold}>{t('app.charge_point.download.link')}</span>
      <section className={styles.logos}>
        {appStoreUrl && (
          <DownloadCard
            href={appStoreUrl}
            iconSrc={FA_ICONS.faAppleLogo}
            label={t('app.charge_point.apple.store.download.label')}
            store={t('app.charge_point.apple.store.name')}
          />
        )}
        {googleStoreUrl && (
          <DownloadCard
            href={googleStoreUrl}
            iconSrc={FA_ICONS.faGooglePlayLogo}
            label={t('app.charge_point.app.store.download.label')}
            store={t('app.charge_point.app.store.name')}
          />
        )}
      </section>
    </section>
  );
};

export default DownloadApps;
