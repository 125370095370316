import React from 'react';

import Image from '../Image';
import { GIF } from '../../constants';
import styles from './styles.module.css';

const Loader = () => {
  return (
    <section className={styles.container}>
      <Image src={GIF.waveLoader} />
    </section>
  );
};

export default Loader;
