import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Components
import Header from '../../components/Header';
import Stripe from './Stripe';

import useConfig from '../../hooks/useConfig';
import { ROUTE_NAMES } from '../../router/route-names';

const initStripe = async (key: string) => {
  return await loadStripe(key);
};

const PaymentMethods = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { stripeKey } = useConfig();

  return (
    <section className="page-container">
      <Header
        showBackButton
        label={t('app.payment_method.header')}
        onClick={() => navigate(`../${ROUTE_NAMES.selectEvse}`)}
      />
      <Elements stripe={initStripe(stripeKey)}>
        <Stripe />
      </Elements>
    </section>
  );
};

export default PaymentMethods;
