import React from 'react';

import ISO6391 from 'iso-639-1';

import { capitalizeFirstLetter } from '../../helpers';

interface LanguageLabelProps {
  code: string;
}

const LanguageLabel = ({ code }: LanguageLabelProps) => {
  /*
  Language code is standartized with the ISO 639 Standard
  https://www.iso.org/iso-639-language-code
  */
  const [language, region] = code.split('_');

  if (!language) return <span>??</span>;
  return (
    <span>{ISO6391.getNativeName(language) ? capitalizeFirstLetter(ISO6391.getNativeName(language)) : language}</span>
  );
};

export default LanguageLabel;
