import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import Header from '../../components/Header';
import Button, { ButtonType } from '../../components/Button';
import Input from '../../components/Input';

// Constants and models
import { ROUTE_NAMES } from '../../router/route-names';
import { ENDPOINT_URL } from '../../constants/endpoints';
import { Payment } from '../../models/payment';

import { handleAndMonitorError, isValidMonth, isValidYear } from '../../helpers';
import { LocalStorage, PAYMENT_KEY } from '../../storage';
import { Http } from '../../http';

import styles from './styles.module.css';
import useConfig from '../../hooks/useConfig';

// checking only the last 4 digits
const LAST_CARD_DIGITS_LENGTH = 4;

const ReEnterCardDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [cardDigits, setCardDigits] = useState<number>();
  const [cardDigitsError, setCardDigitsError] = useState<string | null>(null);
  const [cardExpirationMonth, setCardExpirationMonth] = useState<string>();
  const [cardExpirationYear, setCardExpirationYear] = useState<string>();
  const [cardExpirationError, setCardExpirationError] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const payment = LocalStorage.getItem(PAYMENT_KEY) as Payment | undefined;
  const { name } = useConfig();

  const handleValidation = () => {
    if (!cardDigits || cardDigits?.toString().length !== LAST_CARD_DIGITS_LENGTH) {
      setCardDigitsError(t('app.re_enter_card_details.input.card.details.error'));
      return;
    }
    setCardDigitsError(null);

    if (!cardExpirationMonth || !isValidMonth(cardExpirationMonth)) {
      setCardExpirationError(t('app.re_enter_card_details.input.card.month.expiration.error'));
      return;
    }

    if (!cardExpirationYear || !isValidYear(cardExpirationYear)) {
      setCardExpirationError(t('app.re_enter_card_details.input.card.year.expiration.error'));
      return;
    }

    setCardExpirationError(null);

    if (!payment?.chargePointId || !payment?.evseId) {
      setError(t('app.re_enter_card_details.general.error'));
      return;
    }

    setError(null);
    handleSubmit(payment.chargePointId, payment.evseId, cardDigits.toString(), cardExpirationYear, cardExpirationMonth);
  };

  const handleSubmit = async (
    chargePointId: number,
    evseId: number,
    last4: string,
    expYear: string,
    expMonth: string
  ) => {
    try {
      const validateResponse = await new Http().get(
        ENDPOINT_URL.forgotPin(chargePointId, evseId, last4, expYear.length > 2 ? expYear : `20${expYear}`, expMonth),
        name
      );
      if (!validateResponse?.data?.success) {
        setError(t('app.re_enter_card_details.validation.error'));
        return;
      }

      navigate(`../${ROUTE_NAMES.resetPinCode}`, {
        state: {
          last4,
          expYear,
          expMonth
        }
      });
    } catch (e) {
      handleAndMonitorError(
        `Error while trying to validate card details from ${ENDPOINT_URL.forgotPin(
          chargePointId,
          evseId,
          last4,
          expYear,
          expMonth
        )}: ${e}`
      );
      setError(t('app.re_enter_card_details.validation.error'));
    }
  };

  return (
    <section className="page-container">
      <Header
        showBackButton
        label={t('app.re_enter_card_details.header')}
        onClick={() => navigate(`../${ROUTE_NAMES.enterPinCode}`)}
      />
      <section className={styles.container}>
        <Input
          label={t('app.re_enter_card_details.input.card.details.label')}
          type="number"
          error={cardDigitsError}
          setValue={setCardDigits}
        />
        <div className={styles.cardExpirationContainer}>
          <Input
            label={t('app.re_enter_card_details.input.card.expiration.label')}
            type="text"
            error={cardExpirationError}
            setValue={setCardExpirationMonth}
            showSecond={true}
            setSecondValue={setCardExpirationYear}
          />
        </div>
      </section>
      <Button label={t('app.re_enter_card_details.button')} type={ButtonType.primary} onClick={handleValidation} />
      {error && <span className={'general-error'}>{error}</span>}
    </section>
  );
};

export default ReEnterCardDetails;
