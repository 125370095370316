import styles from "./styles.module.css";

interface ModalProps {
  children: any;
}

const Modal = ({ children }: ModalProps) => {
  return (
    <section className={styles.modal}>
      <section className={styles.content}>{children}</section>
    </section>
  );
};

export default Modal;
