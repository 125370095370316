import axios from 'axios';

export enum HTTPMethod {
  CONNECT = 'CONNECT',
  DELETE = 'DELETE',
  GET = 'GET',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
  TRACE = 'TRACE'
}

const BASE_URL = window.location.origin;

export class Http {
  public get = async <T = any,>(url: string, tenant?: string): Promise<any> => {
    const response = await axios.get<T>(BASE_URL + url, {
      headers: { Tenant: tenant, 'tenant-path': window.location.href }
    });
    return response;
  };

  public post = async <T = any,>(url: string, body: any, tenant?: string): Promise<any> => {
    const response = await axios.post<T>(BASE_URL + url, body, {
      headers: { Tenant: tenant, 'tenant-path': window.location.href }
    });
    return response;
  };

  public patch = async <T = any,>(url: string, body: any, tenant?: string): Promise<any> => {
    const response = await axios.patch<T>(BASE_URL + url, body, {
      headers: { Tenant: tenant, 'tenant-path': window.location.href }
    });
    return response;
  };
}
