import React, { useState } from 'react';

// Components
import Button, { ButtonType } from '../Button';
import Header from '../Header';
import Image from '../Image';
import Notification from '../Notification';

import { FA_ICONS } from '../../constants';

import styles from './styles.module.css';
import { t } from 'i18next';

const PIN_CODE_LENGTH = 4;
const DIGITS = 10;

interface PinCodeProps {
  showBackButton: boolean;
  headerLabel: string;
  buttonLabel: string;
  buttonIcon?: string;
  error?: string | null;
  onBackClick?: () => void;
  onForwardClick: (pinCode: string) => void;
}

const PinCode = ({
  showBackButton,
  headerLabel,
  buttonLabel,
  buttonIcon,
  error,
  onBackClick,
  onForwardClick
}: PinCodeProps) => {
  const [pinCode, setPinCode] = useState('');

  const digitClick = (code: number) => {
    setPinCode(currentCode => (currentCode += code));
  };

  const renderIcons = () => {
    return [...Array(PIN_CODE_LENGTH)].map((_, index) => {
      if (index < pinCode.length) {
        return <Image key={index} src={FA_ICONS.faCircleEntered} />;
      }
      return <Image key={index} src={FA_ICONS.faCircle} />;
    });
  };

  const handleSetPin = () => {
    onForwardClick(pinCode);
    setPinCode('');
  };

  const renderDigits = () => {
    return [...Array(DIGITS)].map((_, index) => (
      <span key={index} className={styles.digit} onClick={() => digitClick((index + 1) % DIGITS)}>
        {(index + 1) % DIGITS}
      </span>
    ));
  };

  return (
    <>
      <Header showBackButton={showBackButton} label={headerLabel} onClick={onBackClick ? onBackClick : undefined} />
      {error && <Notification type="error" show={!!error} header={t(error)} position="unset" showClose={true} />}
      <section className={styles.icons}>{renderIcons()}</section>
      <section className={`${styles.pinContainer} ${pinCode.length === PIN_CODE_LENGTH && styles.disabled}`}>
        {renderDigits()}
      </section>
      <Button
        label={buttonLabel}
        type={ButtonType.primary}
        onClick={handleSetPin}
        icon={buttonIcon}
        disabled={pinCode.length !== PIN_CODE_LENGTH}
      />
    </>
  );
};

export default PinCode;
