import React from 'react';

import Card from '../../Card';
import Image from '../../Image';

import { FA_ICONS } from '../../../constants';

import styles from './styles.module.css';

interface ChargePointCardProps {
  name: string | undefined;
  location: string | undefined;
  className?: string;
}

const ChargePointCard = ({ name, location, className }: ChargePointCardProps) => {
  return (
    <Card className={`${styles.container} ${className}`}>
      <section>
        <Image src={FA_ICONS.faLocationDot} />
      </section>
      <section className={styles.details}>
        <span className={styles.name}>{name}</span>
        <span className={styles.location}>{location}</span>
      </section>
    </Card>
  );
};

export default ChargePointCard;
