export interface Evse {
  id: number;
  physicalReference: string;
  currentType: CurrentType;
  networkId: string;
  hardwareStatus: HardwareStatus;
  powerOptions: {
    maxPower: number;
    maxVoltage: string;
    maxAmperage: null;
    phases: string;
    phaseRotation: string;
  };
  format: string;
  connectors: { id: number; type: ConnectorType | string; format: string }[];
  tariff: {
    id: number;
    name: string;
    type: TariffType;
    description: Record<string, string | null>;
    additionalInformation: {
      en: null;
    };
    learnMoreUrl: {
      en: null;
    };
    dayTariffStart: string;
    nightTariffStart: string;
    pricing: {
      connectionFee: number;
      pricePerKwh: number;
      pricePerPeriod: number;
      pricePeriodInMinutes: number;
      dayPricePerKwh?: number;
      dayIdleFeePerMinute?: number;
      dayPricePerPeriod?: number;
      idleFeePerMinute: number;
      idleFeeGracePeriodMinutes: number;
      nightPricePerPeriod?: number;
      nightIdleFeePerMinute?: number;
      nightPricePerKwh?: number;
      connectionFeeMinimumSessionDuration: number;
      connectionFeeMinimumSessionEnergy: number;
      durationFeeGracePeriod: number;
      minPrice: number;
    };
    currency: string;
    stopSession: {
      timeLimitMinutes: null;
      stopWhenEnergyExceedsKwh: null;
    };
    restrictions: {
      applyToUsersOfChargePointOwner: boolean;
      applyToUsersOfAllRoamingEmsps: boolean;
      applyToAdHocUsers: boolean;
      adHocPreAuthorizeAmount: number;
      applyToUsersOfPartners: any[];
      applyToUsersWithGroups: any[];
      applyToUserGroupIds: any[];
      applyToUsersWithSubscriptions: any[];
    };
    display: any;
  };
}

export enum HardwareStatus {
  available = 'available',
  preparing = 'preparing',
  charging = 'charging',
  suspendedEVSE = 'suspendedEVSE',
  suspendedEV = 'suspendedEV',
  finishing = 'finishing',
  reserved = 'reserved',
  unavailable = 'unavailable',
  faulted = 'faulted',
  occupied = 'occupied',
  null = 'null'
}

export enum CurrentType {
  ac = 'ac',
  dc = 'dc'
}

export enum ConnectorType {
  type1 = 'type1',
  type2 = 'type2',
  type3 = 'type3',
  chademo = 'chademo',
  ccs1 = 'ccs1',
  ccs2 = 'ccs2',
  j1772 = 'j1772',
  'gb-t-ac' = 'gb-t-ac',
  'gb-t-dc' = 'gb-t-dc',
  catarc = 'catarc',
  schuko = 'schuko',
  cee16 = 'cee16',
  cee32 = 'cee32',
  inductive = 'inductive',
  'nema-5-20' = 'nema-5-20',
  'type-e-french' = 'type-e-french',
  'type-g-british' = 'type-g-british',
  'type-j-swiss' = 'type-j-swiss',
  avcon = 'avcon'
}

export interface DetailsSectionProps {
  status: HardwareStatus;
  currency: string;
  dayTariffStart?: string;
  nightTariffStart?: string;
  dayPricePerKwh?: number;
  dayIdleFeePerMinute?: number;
  dayPricePerPeriod?: number;
  nightPricePerPeriod?: number;
  nightIdleFeePerMinute?: number;
  nightPricePerKwh?: number;
  price: number;
  startFee: number;
  minPrice: number;
  deposit: number;
  tenant: string;
  chargePointId: number;
  evseId: number;
  evsePhysicalReference: string;
  connectorId: number;
  connectionFee: number;
  connectionFeeMinimumSessionDuration: number;
  connectionFeeMinimumSessionEnergy: number;
  pricePerPeriod: number;
  pricePerKwh: number;
  pricePeriodInMinutes: number;
  durationFeeGracePeriod: number;
  idleFeeGracePeriodMinutes: number;
  idleFeePerMinute: number;
  type: TariffType;
  description?: Record<string, string | null>;
  additionalInfo?: Record<string, string | null>;
}

export enum TariffType {
  DURATION_ENERGY = 'duration+energy',
  DURATION_ENERGY_TOD = 'duration+energy time of day'
}
