import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import useConfig from '../useConfig';
import { DisplayMode } from '../../constants/display-mode';
import { ROUTE_NAMES } from '../../router/route-names';
import { buildTenantUrl, checkPathnameIncludes } from '../../helpers/routing';

export default function useNavigationHandler() {
  const navigate = useNavigate();
  const { name, displayMode } = useConfig();

  const handleGoHomeButton = useCallback(() => {
    if (displayMode === DisplayMode.Search) {
      navigate(`../${ROUTE_NAMES.selectEvse}`);
    } else {
      if (checkPathnameIncludes('tenant')) {
        navigate(`../${ROUTE_NAMES.selectEvse}`);
      } else {
        navigate(
          buildTenantUrl({
            pathPrefix: '..',
            tenant: name,
            routeName: ROUTE_NAMES.selectEvse
          })
        );
      }
    }
  }, [navigate, displayMode, name]);

  return {
    handleGoHomeButton
  };
}
